import './jquery-global.js';
import 'what-input';
import 'magnific-popup';
import 'jquery-validation';
import 'jquery.steps';


// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
// window.jQuery = $;

require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document).foundation();

$('.sticky-header').on('sticky.zf.stuckto:top', function(){
    $(this).addClass('shrink');
}).on('sticky.zf.unstuckfrom:top', function(){
    $(this).removeClass('shrink');
})

$(document ).ready(function() {
    $(function() {
        $("a.no-link").click(function(){
            return false;
        });
        if ($('.twentytwenty').length > 0) {
            $(".twentytwenty").twentytwenty({
                default_offset_pct: 0.5, // How much of the before image is visible when the page loads
                orientation: 'horizontal', // Orientation of the before and after images ('horizontal' or 'vertical')
                before_label: 'Voor behandeling', // Set a custom before label
                after_label: 'Na behandeling', // Set a custom after label
                // no_overlay: true, //Do not show the overlay with before and after
                // move_slider_on_hover: true, // Move slider on mouse hover?
                // move_with_handle_only: true, // Allow a user to swipe anywhere on the image to control slider movement.
                // click_to_move: false // Allow a user to click (or tap) anywhere on the image to move the slider to that location.
            });
        }
    });

    // Add it after jquery.magnific-popup.js and before first initialization code
    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'Afsluiten (Esc)',
        tLoading: 'Bezig met laden...',
        gallery: {
            enabled: false,
            navigateByImgClick: true,
            tPrev: 'Vorige', // Alt text on left arrow
            tNext: 'Volgende', // Alt text on right arrow
            tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
        },
        image: {
            tError: '<a href="%url%">De afbeelding</a> kan niet worden geladen' // Error message when image could not be loaded
        },
        ajax: {
            tError: '<a href="%url%">De inhoud</a> kan niet worden geladen.' // Error message when ajax request failed
        }
    });
    if ($('.gallery').length > 0)
    {
        $('.gallery').each(function() { // the containers for all your galleries
            $(this).magnificPopup({
                delegate: 'a',
                type: 'image',
                closeOnContentClick: true,
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [1,2] // Will preload 0 - before current, and 1 after the current image
                }
            });
        });
    }
    if ($('.open-in-iframe').length > 0)
    {
        $('.open-in-iframe').each(function() { // the containers for popups
            $(this).magnificPopup({
                type: 'iframe',
                closeOnContentClick: false,
                closeOnBgClick: false,
                enableEscapeKey: false
            });
        });
    }
    if ($('.before-after-popup').length > 0)
    {
        $('.before-after-popup').each(function() { // the containers for popups
            $(this).magnificPopup({
                type: 'inline',
                closeOnContentClick: true,
                closeOnBgClick: true,
                enableEscapeKey: true
            });
        });
    }

    if ($('#online-huidtest').length > 0)
    {
        jQuery.extend(jQuery.validator.messages, {
            required: "* Dit is een verplicht veld.",
            remote: "Controleer dit veld.",
            email: "Vul hier een geldig e-mailadres in.",
            url: "Vul hier een geldige URL in.",
            date: "Vul hier een geldige datum in.",
            dateISO: "Vul hier een geldige datum in (ISO-formaat).",
            number: "Vul hier een geldig getal in.",
            digits: "Vul hier alleen getallen in.",
            creditcard: "Vul hier een geldig creditcardnummer in.",
            equalTo: "Vul hier dezelfde waarde in.",
            extension: "Vul hier een waarde in met een geldige extensie.",
            maxlength: jQuery.validator.format("Vul hier maximaal {0} tekens in."),
            minlength: jQuery.validator.format("Vul hier minimaal {0} tekens in."),
            rangelength: jQuery.validator.format("Vul hier een waarde in van minimaal {0} en maximaal {1} tekens."),
            range: jQuery.validator.format("Vul hier een waarde in van minimaal {0} en maximaal {1}."),
            max: jQuery.validator.format("Vul hier een waarde in kleiner dan of gelijk aan {0}."),
            min: jQuery.validator.format("Vul hier een waarde in groter dan of gelijk aan {0}."),

            // for validations in additional-methods.js
            iban: "Vul hier een geldig IBAN in.",
            dateNL: "Vul hier een geldige datum in.",
            phoneNL: "Vul hier een geldig Nederlands telefoonnummer in.",
            mobileNL: "Vul hier een geldig Nederlands mobiel telefoonnummer in.",
            postalcodeNL: "Vul hier een geldige postcode in.",
            bankaccountNL: "Vul hier een geldig bankrekeningnummer in.",
            giroaccountNL: "Vul hier een geldig gironummer in.",
            bankorgiroaccountNL: "Vul hier een geldig bank- of gironummer in."
        });

        let form = $("#online-huidtest");

        form.validate({
            lang: 'nl',
            errorPlacement: function errorPlacement(error, element) {
                element.before(error);
            },
            rules: {
                confirm: {
                    equalTo: "#password"
                }
            }
        });

        form.steps({
            headerTag: "h2",
            bodyTag: "section",
            transitionEffect: "slideLeft",
            titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
            onStepChanging: function (event, currentIndex, newIndex) {
                form.validate().settings.ignore = ":disabled,:hidden";
                return form.valid();
            },
            onFinishing: function (event, currentIndex) {
                form.validate().settings.ignore = ":disabled";
                return form.valid();
            },
            onFinished: function (event, currentIndex) {
                form.submit();
            },
            /* Labels */
            labels: {
                cancel: "Annuleren",
                current: "huidige stap:",
                pagination: "Paginering",
                finish: "Versturen",
                next: "Volgende",
                previous: "Vorige",
                loading: "Bezig met laden ..."
            }
        });
        // form.smartWizard({
        //     selected: 0, // Initial selected step, 0 = first step
        //     justified: true, // Nav menu justification. true/false
        //     autoAdjustHeight: true, // Automatically adjust content height
        //     backButtonSupport: true, // Enable the back button support
        //     enableUrlHash: true, // Enable selection of the step based on url hash
        //     transition: {
        //         animation: 'fade', // Animation effect on navigation, none|fade|slideHorizontal|slideVertical|slideSwing|css(Animation CSS class also need to specify)
        //         speed: '400', // Animation speed. Not used if animation is 'css'
        //         easing: '', // Animation easing. Not supported without a jQuery easing plugin. Not used if animation is 'css'
        //         prefixCss: '', // Only used if animation is 'css'. Animation CSS prefix
        //         fwdShowCss: '', // Only used if animation is 'css'. Step show Animation CSS on forward direction
        //         fwdHideCss: '', // Only used if animation is 'css'. Step hide Animation CSS on forward direction
        //         bckShowCss: '', // Only used if animation is 'css'. Step show Animation CSS on backward direction
        //         bckHideCss: '', // Only used if animation is 'css'. Step hide Animation CSS on backward direction
        //     },
        //     toolbar: {
        //         position: 'bottom', // none|top|bottom|both
        //         showNextButton: true, // show/hide a Next button
        //         showPreviousButton: true, // show/hide a Previous button
        //         extraHtml: '' // Extra html to show on toolbar
        //     },
        //     anchor: {
        //         enableNavigation: true, // Enable/Disable anchor navigation
        //         enableNavigationAlways: false, // Activates all anchors clickable always
        //         enableDoneState: true, // Add done state on visited steps
        //         markPreviousStepsAsDone: true, // When a step selected by url hash, all previous steps are marked done
        //         unDoneOnBackNavigation: false, // While navigate back, done state will be cleared
        //         enableDoneStateNavigation: true // Enable/Disable the done state navigation
        //     },
        //     keyboard: {
        //         keyNavigation: true, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
        //             keyLeft: [37], // Left key code
        //             keyRight: [39] // Right key code
        //     },
        //     lang: { // Language variables for button
        //         next: 'Volgende',
        //         previous: 'Vorige'
        //     },
        //     disabledSteps: [], // Array Steps disabled
        //     errorSteps: [], // Array Steps error
        //     warningSteps: [], // Array Steps warning
        //     hiddenSteps: [], // Hidden steps
        //     getContent: null // Callback function for content loading
        // });
        // form.on("leaveStep", function(e, anchorObject, idx, stepNumber, stepDirection) {
        //     alert(stepDirection);
        //     if (stepDirection === "forward") {
        //         form.validate().settings.ignore = ":disabled,:hidden";
        //         return form.valid();
        //     }
        //     form.validate().settings.ignore = ":disabled,:hidden";
        //     return form.valid();
        //     return true;
        // });
    }
    if ($('[data-dependent]').length > 0) {
        $('[data-dependent]').each(function () {
            var $ele = $(this);
            var dependsOn = $ele.data('dependent');
            $.each(dependsOn, function (target, value) {
                $(target).on('change', function () {
                    if ($(this).val() === value) {
                        $ele.show();
                    } else {
                        $ele.hide();
                    }
                });
            });
        });
    }
});